<template>
  <div class="header">
    <div class="collapse-btn" @click="collapseChage">
      <i :class="[collapse ? 'el-icon-s-fold' : 'el-icon-s-unfold', 'icon']">
      </i>
    </div>
    <div class="logo">
      <img src="../../assets/police.png" />
      <h1>{{ title }}</h1>
    </div>
    <div class="header-right">
      <div class="header-user-con">
        <div class="btn-fullscreen" @click="handleFullScreen">
          <el-tooltip
            effect="dark"
            :content="fullscreen ? `取消全屏` : `全屏`"
            placement="bottom"
          >
            <i class="el-icon-rank"></i>
          </el-tooltip>
        </div>
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            {{ username }} -【{{ roleName }}】
            <i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="changepwd">修改密码</el-dropdown-item>
            <el-dropdown-item divided command="loginout">
              退出登录
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <!-- 修改密码框 -->
    <el-dialog
      title="修改密码"
      width="500px"
      :visible.sync="selectDlgShow"
      :close-on-click-modal="true"
    >
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form ref="ruleForm" :model="form" label-width="150px">
          <el-form-item label="旧密码" prop="oldPwd" required>
            <el-input
              v-model="form.oldPwd"
              placeholder="请输入旧密码"
              show-password
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPwd" required>
            <el-input
              v-model="form.newPwd"
              placeholder="请输入新密码"
              show-password
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="再次确定新密码" prop="newPwd2" required>
            <el-input
              v-model="form.newPwd2"
              placeholder="请再次确定新密码"
              show-password
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="resetForm('ruleForm')">重 置</el-button>
        <el-button
          size="small"
          type="primary"
          @click="changePassword('ruleForm')"
        >
          保 存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import bus from '../common/bus';
import { updatePassword } from '@/api/admin/user';
export default {
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      name: 'sunny',
      message: 2,
      collapse: false,
      fullscreen: false,
      selectDlgShow: false,
      dlgLoading: false,
      form: {
        userId: sessionStorage.getItem('userId'),
        oldPwd: '',
        newPwd: '',
        newPwd2: ''
      }
    };
  },
  computed: {
    username() {
      return sessionStorage.getItem('username');
    },
    roleName() {
      return sessionStorage.getItem('roleName');
    }
  },
  mounted() {
    if (document.body.clientWidth < 1500) {
      this.collapseChage();
    }
  },
  methods: {
    // 用户名下拉菜单选择事件
    handleCommand(command) {
      if (command == 'loginout') {
        this.loginOut();
      }
      if (command == 'changepwd') {
        this.selectDlgShow = true;
      }
    },
    //退出
    loginOut() {
      sessionStorage.clear();
      this.$router.replace('/login');
    },
    //修改密码
    changePassword(formName) {
      console.log(this.form);
      this.$refs[formName].validate(valid => {
        if (valid) {
          let pwd = this.form.newPwd;
          let pwd2 = this.form.newPwd2;
          if (pwd != pwd2) {
            this.$message({
              message: '两次输入的新密码不一致，请重新输入',
              type: 'warning'
            });
            return;
          }

          this.dlgLoading = true;
          updatePassword(this.form)
            .then(resp => {
              if (resp.code == 200) {
                this.$message({
                  message: '操作成功',
                  type: 'success'
                });
                this.selectDlgShow = false;
                this.loginOut();
              } else {
                this.$message.error(resp.msg);
              }
              this.dlgLoading = false;
            })
            .catch(e => {
              this.dlgLoading = false;
              this.$message.error(e);
            });
        } else {
          return false;
        }
      });
    },
    // 侧边栏折叠
    collapseChage() {
      this.collapse = !this.collapse;
      bus.$emit('collapse', this.collapse);
    },
    // 全屏事件
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped lang="scss">
.header {
  position: relative;
  width: 100%;
  height: 70px;
  color: #fff;
}
.collapse-btn {
  float: left;
  display: flex;
  align-items: center;
  padding: 0 21px;
  height: 70px;
  cursor: pointer;
  background-color: lighten(#242f42, 5);
}

.icon {
  font-size: 28px;
}

.header-right {
  float: right;
  padding-right: 50px;
}

.header-user-con {
  display: flex;
  height: 70px;
  align-items: center;
}

.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}

.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}

.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
}

.btn-bell .el-icon-bell {
  color: #fff;
}

.user-name {
  margin-left: 10px;
}

.user-avator {
  margin-left: 20px;
}

.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.el-dropdown-link {
  color: #fff;
  cursor: pointer;
}

.el-dropdown-menu__item {
  text-align: center;
}

// 大字号样式
.logo {
  float: left;
  height: 100%;
  display: flex;
  align-items: center;

  img {
    margin-left: 1rem;
    margin-right: 1rem;
    width: 48px;
    height: 48px;
  }
}

.el-dropdown {
  font-size: 20px;

  &-menu__item {
    padding: 24px 100px;
    line-height: initial;
    font-size: 20px;

    &:before {
      display: none;
    }
  }
}
</style>
